import { render, staticRenderFns } from "./index.vue?vue&type=template&id=004cf8c6&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=004cf8c6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "004cf8c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingWordSlider: require('/Users/rik24d/code/beta.gouv.fr/docurba/Docurba/components/Landing/WordSlider.vue').default,LayoutsBannerAlert: require('/Users/rik24d/code/beta.gouv.fr/docurba/Docurba/components/Layouts/BannerAlert.vue').default,LandingSearchForm: require('/Users/rik24d/code/beta.gouv.fr/docurba/Docurba/components/Landing/SearchForm.vue').default,LandingSlidingTabs: require('/Users/rik24d/code/beta.gouv.fr/docurba/Docurba/components/Landing/SlidingTabs.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
